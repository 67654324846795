<template>
  <el-form
    ref="commonForm"
    :label-width="labelWidth"
    :model="mainForm"
    :rules="mainRules"
  >
    <slot name="formtop"></slot>
    <el-row :gutter="65">
      <el-col :md="24" :lg="7">
        <!-- 中文名称 -->
        <el-form-item :label="$t('form.product.cnName')" prop="name">
          <el-input
            v-model="mainForm.name"
            :placeholder="$t('placeholder.defaultInput')"
            :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="24" :lg="7">
        <!-- 英文名称 -->
        <el-form-item :label="$t('form.product.enName')" prop="name_en">
          <el-input
            v-model="mainForm.name_en"
            :placeholder="$t('placeholder.defaultInput')"
            :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :md="24" :lg="7">
        <!-- 排序优先级 -->
        <el-form-item :label="$t('form.product.rankingPriority')" prop="rank">
          <el-input
            v-model="mainForm.rank"
            type="number"
            :placeholder="$t('placeholder.defaultInput')"
            :maxlength="constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <slot name="formbottom"></slot>
    <el-row>
      <el-divider></el-divider>
    </el-row>
    <el-row type="flex" justify="center">
      <el-button @click="handleFormCancelClick">{{ $t('button.cancel') }}</el-button>
      <el-button type="primary" @click="handleFormSubmitClick">{{ $t('button.confirm') }}</el-button>
    </el-row>
    </el-form>
</template>

<script>
  import constant from '@/utils/constant.js'
  import loading from '@/utils/loading.json'
  export default {
    props: {
      rules: {
        type: Object,
        default: () => {}
      },
      hasNameAndNameEnIpt: {
        type: Boolean,
        default: true
      },
      mainForm: {
        type: Object,
        default: () => {}
      },
      submitForm: {
        type: Function,
        default() {
          return 'Default function'
        }
      },
      labelWidth: {
        type: String,
        default: '120px'
      },
      labelId: {
        type: String,
        default: ''
      },
      routerName: {
        type: String,
        default: 'restsManagerLabelList'
      }
    },
    data () {
      return {
        constant,
        nameAndNameEnRules: {
          name: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          name_en: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ],
          rank: [
            { required: true, message: this.$t('text.required'), trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
      mainRules () {
        if (this.hasNameAndNameEnIpt) return Object.assign(this.rules, this.nameAndNameEnRules)
        return this.rules
      }
    },
    methods: {
      setGlobalLoading (show) {
        const that = this;
        show ? that.$store.state.app.loading = that.$loading(loading) : that.$store.state.app.loading.close()
      },
      handleFormCancelClick () {
        this.$router.go(-1)
      },
      handleFormSubmitClick () {
        const that = this;
        that.$refs.commonForm.validate(async (valid) => {
          if (valid) {
            try {
              that.setGlobalLoading(true)
              const res = await that.submitForm(that.mainForm, that.labelId)
              if (res.status == 200) {
                  that.$message({
                  type: 'success',
                  message: that.$t('message.operateSuccess')
                })
                const timer = setTimeout(() => {
                  clearTimeout(timer)
                  that.$router.push({
                    name: this.routerName
                  })
                }, constant.ROUTER_CHANGE_INTERVAL)
              }
              that.setGlobalLoading(false)
            } catch (error) {
              
            }
            //that.$emit('form', that.mainForm)
          } else {
            that.$message.error(that.$t('message.formIsRequire'))
            return false
          }
        })
      }
    }
  }
</script>