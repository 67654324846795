<template>
  <el-card>
    <CommonForm
      :submit-form="submitFormFunc"
      :rules="{}"
      :main-form.sync="mainForm"
      :label-id="id"
      :routerName="'restsManagerFacilityList'"
    >
    <template #formtop>
        <el-row>
          <el-col :md="24" :lg="24">
            <!-- 上架 -->
            <el-form-item :label="$t('form.product.online')" prop="online">
              <el-switch v-model="mainForm.online"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <template #formbottom>
        <el-row>
          <el-col :md="24" :lg="24">
            <!-- 图片 -->
            <el-form-item :label="$t('form.product.image')" prop="icon">
              <importImg
                :limit="1"
                @change="handleMainFormImgChange"
                :data="imgList"
                :watchDataVisible="true"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </template>
    </CommonForm>
  </el-card>
</template>

<script>
import CommonForm from '@/components/commonForm/index'
import importImg from '@/components/importImg.vue'

import {
  createRestFacilities,
  getRestFacilitiesInfoPreview,
  updateRestFacilitiesInfo
} from '@/services/rests'
import loading from '@/utils/loading.json'
export default {
  components: {
    CommonForm,
    importImg
  },
  data () {
    return {
      submitFormFunc: createRestFacilities,
      mainForm: {
        online: true,
        name: null,
        name_en: null,
        rank: null,
        images_attributes: []
      },
      id: '',
      imgList: []
    }
  },
  created () {
    this.id = this.$route.query.id || ''
    this.initData()
  },
  methods: {
    handleMainFormImgChange (fileData) {
      const _this = this
      _this.mainForm.images_attributes = []
      if (fileData && fileData.length > 0) {
        fileData.forEach(item => {
          if (!item._destroy) {
            _this.mainForm.images_attributes.push({
              remote_image_url: item.url,
              display_order: item.display_order,
              id: item.id || ''
            })
          }
        })
      }
    },
    async initData() {
      if (this.id) {
        this.$store.state.app.loading = this.$loading(loading)
        this.submitFormFunc = updateRestFacilitiesInfo
        const res = await getRestFacilitiesInfoPreview(this.id)
        if (res.status === 200 && res.data) {
          const facilityRes = res.data.rest_facility
          if (facilityRes.images && facilityRes.images.length > 0) {
            this.imgList = facilityRes.images
            delete facilityRes.images
          }
          this.mainForm = {...facilityRes}
        }
        this.$store.state.app.loading.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>